import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "common-layout",
  ref: "mainView"
}
const _hoisted_2 = { class: "content-wrap" }
const _hoisted_3 = { class: "top_bg" }
const _hoisted_4 = { class: "time" }
const _hoisted_5 = { class: "top-title" }

import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import Timer from "@/components/Timer.vue";
interface Page {
  name: string;
  path: string;
  icon: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  title: {
    type: String,
    default: "京能能+超充运营管理平台",
  },
  defaultFooter: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {

const props = __props;

const currentPage = ref("");
const router = useRouter();

const pages: Page[] = [
  {
    name: "整体态势",
    path: "/OverallSituation",
    icon: "all",
  },
  {
    name: "智慧停充",
    path: "/intelligentDecharging",
    icon: "charge",
  },
  {
    name: "智慧微网",
    path: "/SmartMicrogrid",
    icon: "web",
  },
];

// 在组件挂载时读取当前路由路径
onMounted(() => {
  currentPage.value = router.currentRoute.value.path;
});

function jumpPage(path: string) {
  router.push(path);
  currentPage.value = path;
}

return (_ctx: any,_cache: any) => {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_svg_icon, {
          iconClass: "time",
          svgStyle: {
            height: '24px',
            width: '24px',
          }
        }),
        _createVNode(Timer)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(props.title), 1)
    ])
  ], 512))
}
}

})