<template>
  <div>
    <a-spin :spinning="spinning">
      <div id="container"></div>
    </a-spin>
  </div>
</template>
<script setup>
const stationTypeOption = [
  { label: '出租车（专用）', value: 103 },
  { label: '个人', value: 50 },
  { label: '环卫（专用）', value: 101 },
  { label: '公共', value: 1 },
  { label: '物流（专用）', value: 102 },
  { label: '公交（专用）', value: 100 },
  { label: '其他', value: 255 },
]
const operationStatusOption = [
  { label: '建设中', value: '01', name: 'green' },
  { label: '运营中', value: '02', name: 'blue' },
  { label: '停运中', value: '03', name: 'yellow' },
  { label: '检修中', value: '04', name: 'red' },
]
import * as echarts from 'echarts'
import moment from 'moment'
import { preloadImg } from '@/utils/index.js'
import GeoJson_beijing from '@/assets/geojson/beijing.json'
const img_point_red = require('@/assets/images/redPoint.png')
const img_point_yellow = require('@/assets/images/yellowPoint.png')
const img_point_blue = require('@/assets/images/bluePoint.png')
const img_point_green = require('@/assets/images/greenPoint.png')
import { onMounted, ref, computed, nextTick, onUnmounted } from 'vue'
import { queryList } from '@/api/index.js'
const timer = ref(null)
const backgroundImage = require('@/assets/images/beijing2.png')
const data = ref([])
const myChart = ref({})
const spinning = ref(true)
const geoCoordArr = ref([])
const redPointData = computed(() => {
  return data.value.filter((item) => {
    return item.operationStatus == '04'
  })
})
const yellowPointData = computed(() => {
  return data.value.filter((item) => {
    return item.operationStatus == '03'
  })
})
const bluePointData = computed(() => {
  return data.value.filter((item) => {
    return item.operationStatus == '02'
  })
})
const greenPointData = computed(() => {
  return data.value.filter((item) => {
    return item.operationStatus == '01'
  })
})
const imageDom = ref(null)
const option = computed(() => {
  return {
    // 地图纹理及重影
    geo: [
      {
        map: 'beijing',
        layoutCenter: ['50%', '50%'],

        show: true,
        zlevel: 1,
        roam: false,
        label: {
          emphasis: {
            show: false,
          },
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: '#5ed3fa',
          areaColor: {
            image: imageDom.value,
            repeat: 'no-repeat',
          },
          emphasis: {
            areaColor: {
              image: imageDom.value,
              repeat: 'no-repeat',
            },
          },
        },
        tooltip: {
          show: false,
        },
        ...{
          zoom: 1,
          layoutSize: 780,
        },
      },
      // 重影
      {
        type: 'map',
        map: 'beijing',
        zlevel: -1,
        layoutCenter: ['50.5%', '51%'],

        roam: false,
        silent: true,
        itemStyle: {
          normal: {
            borderWidth: 1,
            // borderColor:"rgba(17, 149, 216,0.6)",
            borderColor: '#5ed3fa',
            areaColor: '#5ed3fa',
          },
        },
        ...{
          zoom: 1,
          layoutSize: 780,
        },
      },
      {
        type: 'map',
        map: 'beijing',
        zlevel: -2,
        layoutCenter: ['51%', '51.5%'],

        roam: false,
        silent: true,
        itemStyle: {
          normal: {
            borderWidth: 1,
            borderColor: '#4798b6',
            areaColor: '#4798b6',
          },
        },
        ...{
          zoom: 1,
          layoutSize: 780,
        },
      },
      {
        type: 'map',
        map: 'beijing',
        zlevel: -3,
        // aspectScale: 1,
        zoom: 1,
        layoutCenter: ['51.5%', '52%'],

        roam: false,
        silent: true,
        itemStyle: {
          normal: {
            borderWidth: 2,
            borderColor: '#285c72',
            areaColor: '#377c9a',
            shadowColor: 'rgba(63, 218, 255,0.6)',
            shadowBlur: 35,
          },
        },
        ...{
          zoom: 1,
          layoutSize: 780,
        },
      },
    ],
    tooltip: {
      trigger: 'item',
    },
    series: [
      // 主地图
      {
        name: 'beijing',
        type: 'map',
        map: 'beijing',
        zoom: 1,
        zlevel: 2,
        showLegendSymbol: true,
        label: {
          show: true,
          color: '#1ED8FD',
          fontSize: '14px',
          fontFamily: 'PuHuiTi',
          emphasis: {
            show: true,
            textStyle: {
              color: '#2DDAFC',
              fontSize: 14,
            },
          },
        },
        itemStyle: {
          normal: {
            areaColor: 'rgba(25, 69, 102, 0)',
            borderColor: '#53bdf9',
            borderWidth: 4,
            shadowColor: 'rgba(63, 218, 255,0.6)',
            shadowBlur: 35,
          },
          emphasis: {
            borderWidth: 2,
            borderColor: '#5ed3fa',
            areaColor: 'rgba(25, 69, 102, 0.65)',
          },
        },
        select: {
          disabled: true,
        },
        layoutCenter: ['50%', '50%'],
        layoutSize: '1000',
        markPoint: {
          symbol: 'none',
        },

        tooltip: { show: false },
        data: [],
        ...{
          zoom: 1,
          layoutSize: 780,
        },
      },
      // 标点 - 红色
      {
        type: 'scatter',
        coordinateSystem: 'geo',
        geoIndex: 0,
        zlevel: 5,
        symbol: 'image://' + img_point_red,
        symbolSize: [70.4, 64.8],
        symbolOffset: [0, -20],
        data: redPointData.value,
        tooltip: {
          show: true,
          position: 'top',
          formatter: function ({ data }) {
            return getTip(data)
          },
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(0,0,0,0)',
          extraCssText: 'box-shadow: none;padding-bottom:0',
        },
      },
      // 标点 - 黄色
      {
        type: 'scatter',
        coordinateSystem: 'geo',
        geoIndex: 0,
        zlevel: 6,
        symbol: 'image://' + img_point_yellow,
        symbolSize: [70.4, 64.8],
        symbolOffset: [0, -30],
        data: yellowPointData.value,
        tooltip: {
          show: true,
          position: 'top',
          formatter: function ({ data }) {
            return getTip(data)
          },
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(0,0,0,0)',
          extraCssText: 'box-shadow: none;padding-bottom:0',
        },
      },
      // 标点 - 蓝色
      {
        type: 'scatter',
        coordinateSystem: 'geo',
        geoIndex: 0,
        zlevel: 7,
        symbol: 'image://' + img_point_blue,
        symbolSize: [70.4, 64.8],
        symbolOffset: [0, -30],
        data: bluePointData.value,
        tooltip: {
          show: true,
          position: 'top',
          formatter: function ({ data }) {
            return getTip(data)
          },
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(0,0,0,0)',
          extraCssText: 'box-shadow: none;padding-bottom:0',
        },
      },
      // 标点 - 绿色
      {
        type: 'scatter',
        coordinateSystem: 'geo',
        geoIndex: 0,
        zlevel: 5,
        symbol: 'image://' + img_point_green,
        symbolSize: [70.4, 64.8],
        symbolOffset: [0, -20],
        data: greenPointData.value,
        tooltip: {
          show: true,
          position: 'top',
          formatter: function ({ data }) {
            console.log(getTip(data), 8888)
            return getTip(data)
          },
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(0,0,0,0)',
          extraCssText: 'box-shadow: none;padding-bottom:0',
        },
      },
      //标点 - 城市名称
      [
        {
          type: 'scatter',
          coordinateSystem: 'geo',
          geoIndex: 0,
          zlevel: 8,
          data: geoCoordArr.value,
          symbolSize: 0,
          symbolOffset: [0, 10],
          label: {
            show: true,
            formatter: function (params) {
              var name = params.data[2].areaName
              return name
            },
            color: '#FFFFFF',
            fontSize: '16px',
            fontFamily: 'PuHuiTi',
            emphasis: {
              show: true,
            },
          },
          emphasis: {
            show: true,
          },
        },
      ],
    ],
  }
})
async function getData() {
  const [res, err] = await queryList()
  console.error('=====>', res)

  if (res.code == '10000') {
    data.value = res.data.map((item) => {
      const { lat, lon, ...obj } = item
      return {
        ...obj,
        value: [lon, lat],
      }
    })
  }
  const json = GeoJson_beijing
  if (!json) return
  const geoCoordMap = (json?.features || []).reduce((acc, { properties }) => {
    acc[properties?.adcode || properties?.code] = {
      areaName: properties?.name,
      coord: properties?.cp || properties?.center,
    }
    return acc
  }, {})
  geoCoordArr.value = Object.keys(geoCoordMap).map((key) => {
    return [
      ...geoCoordMap[key].coord,
      {
        name: key,
        areaName: geoCoordMap[key].areaName,
      },
    ]
  })
  nextTick(async () => {
    await initMap()
  })
}
const getName = (val, option, str) => {
  return !str
    ? option.filter((item) => {
        return item.value == val
      })[0].label
    : option.filter((item) => {
        return item.value == val
      })[0].name
}
const getTip = (data) => {
  return `<div class="chart-tooltip ${getName(
    data.operationStatus,
    operationStatusOption,
    'name'
  )}"><div class="chart-tooltip-title">
              <div class="title-top"><span class="title">${
                data.stationName
              }</span><span class="status">${getName(
    data.operationStatus,
    operationStatusOption
  )}</span></div>
              <div class="title-bot"><span class="icon"></span>${
                data.stationAddress
              }</div>
              </div><div class="chart-tooltip-content">
                <div>
                  <span>站点类型</span>
                  <span>${getName(data.stationType, stationTypeOption)}</span>
                </div>
                <div>
                  <span>充电枪数量</span>
                  <span>${data.gunNum}</span>
                </div>
                <div>
                  <span>投运日期</span>
                  <span>${
                    moment(data.openDate).format('YYYY-MM-DD') || '--'
                  }</span>
                  </div>
                <div>
                  <span>上线日期</span>
                  <span>${moment(data.onlineDate).format('YYYY-MM-DD')}</span>
                  </div>
                </div>
                </div>`
}
onMounted(() => {
  echarts.registerMap('beijing', { geoJSON: GeoJson_beijing })
  nextTick(async () => {
    let dom = document.getElementById('container')
    myChart.value = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false,
      width: 870,
      height: 990,
    })
    initMap(true)
  })
  getData()
  timer.value = setInterval(() => {
    getData()
  }, 10000)
})
onUnmounted(() => {
  clearInterval(timer.value)
  timer.value = null
})
async function initMap(isFirst = false) {
  spinning.value = true
  if (!myChart.value?.setOption) return
  // 地图山川纹理
  imageDom.value = document.createElement('canvas')
  var ctx = imageDom.value.getContext('2d')
  const img = await preloadImg({
    src: backgroundImage,
    width: 1100,
    height: 1500,
  })

  imageDom.value.width = img.width
  imageDom.value.height = img.height
  // 将图片绘制到 canvas 上
  // ctx.drawImage(img, 0, 0, imageDom.value.width, imageDom.value.height)
  myChart.value.setOption(option.value, true, true)
  spinning.value = false
  if (!isFirst) return
  // 监听鼠标移入事件
  window.myChart = myChart.value
  myChart.value.on(
    'mouseover',
    function ({ seriesIndex, name, dataIndex, data }) {
      let bindArr = [0, 1, 2]
      bindArr = [0, 1, 2, 4]
      if (bindArr.includes(seriesIndex)) {
        myChart.value?.dispatchAction({
          type: 'downplay',
          seriesIndex: [0, 1, 2, 4],
        })
        myChart.value?.dispatchAction({
          type: 'hideTip',
        })
        myChart.value?.dispatchAction({
          type: 'highlight',
          seriesIndex: bindArr,
          name,
        })
        myChart.value?.dispatchAction({
          type: 'showTip',
          seriesIndex: 1,
          name,
        })
        myChart.value?.dispatchAction({
          type: 'showTip',
          seriesIndex: 2,
          name,
        })
      }
    }
  )

  // 监听鼠标移出事件
  myChart.value.on('mouseout', function () {
    let bindArr = [0, 1, 2]
    bindArr = [0, 1, 2, 4]
    myChart.value?.dispatchAction({
      type: 'downplay',
      seriesIndex: bindArr,
    })
  })
}
</script>
<style lang="less" scoped>
::v-deep {
  .chart-tooltip {
    font-family: AlibabaPuHuiTi;
    width: 200px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px;
    justify-content: center;
    width: 251px;

    &-title {
      width: 100%;
      margin-bottom: 3px;
      font-family: PuHuiTi;
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
      padding: 8px;
      background: linear-gradient(
        90deg,
        rgba(9, 86, 122, 0.8) 0%,
        rgba(4, 142, 181, 0.96) 49%,
        rgba(9, 86, 122, 0.8) 100%
      );

      box-sizing: border-box;
      border: 0.6px solid;
      border-image: linear-gradient(
        0deg,
        rgba(41, 205, 250, 0) 0%,
        #29cdfa 100%
      );
      .title-top {
        display: flex;
        align-items: center;
        .title {
          font-family: YouSheBiaoTiHei;
          color: #f7ffff;
          font-size: 18px;
          font-weight: normal;
        }
        .status {
          font-family: PingFang SC;
          font-size: 8px;
          font-weight: 600;
          padding: 2px;
          color: #29f1fa;
          border: 1px solid #29f1fa;
          margin-left: 8px;
          height: 15px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          border-radius: 1px;
          background: rgba(41, 241, 250, 0.1);
        }
      }

      .title-bot {
        margin-top: 5px;
        font-family: AlibabaPuHuiTi;
        font-size: 14px;
        font-weight: normal;
        line-height: 16.8px;
        letter-spacing: 0px;
        color: #ffffff;
        display: flex;
        align-items: center;
        .icon {
          background-image: url(~@/assets/images/loc.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 14.25px;
          height: 15.83px;
          display: inline-block;
          margin-right: 3px;
        }
      }
    }

    &-content {
      font-size: 14px;
      font-weight: normal;
      line-height: 16.8px;
      width: 100%;
      font-family: AlibabaPuHuiTi;
      background-image: url(~@/assets/images/conbg.png);
      background-size: 100% 100%;
      padding: 15px 8px;
      color: #ffffff;
      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        &:nth-last-child(0) {
          margin-bottom: 0px;
        }
        > span {
          &:nth-of-type(2) {
            color: #29f1fa;
          }
        }
      }
    }
  }
}
::v-deep {
  .green {
    .chart-tooltip-title {
      background: linear-gradient(90deg, #418340 0%, #0b961e 49%, #3f833e 100%);
      border-image: linear-gradient(
        0deg,
        rgba(41, 205, 250, 0) 0%,
        #98ffa4 100%
      );
      .status {
        color: rgba(98, 219, 108, 1);
        border: 1px solid #62db6c;
        background: rgba(98, 219, 108, 0.2);
      }
      .icon {
        background-image: url(~@/assets/images/greenloc.png) !important;
      }
    }
    .chart-tooltip-content {
      background-image: url(~@/assets/images/greenbg.png);
      > div {
        > span {
          &:nth-of-type(2) {
            color: #62db6c;
          }
        }
      }
    }
  }
  .red {
    .chart-tooltip-title {
      background: linear-gradient(90deg, #a10c01 0%, #e13c2c 49%, #a70c00 100%);
      border-image: linear-gradient(
        0deg,
        rgba(41, 205, 250, 0) 0%,
        #d2524a 100%
      );
      .status {
        color: #fff;
        border: 1px solid #ff6859;
        background: #c83b2d;
      }
      .icon {
        background-image: url(~@/assets/images/redloc.png) !important;
      }
    }
    .chart-tooltip-content {
      background-image: url(~@/assets/images/redbg.png);
      > div {
        > span {
          &:nth-of-type(2) {
            color: #c83b2d;
          }
        }
      }
    }
  }
  .yellow {
    .chart-tooltip-title {
      background: linear-gradient(90deg, #a48d0f 0%, #c1aa28 49%, #988008 100%);
      border-image: linear-gradient(
        0deg,
        rgba(41, 205, 250, 0) 0%,
        #ffef05 100%
      );
      .status {
        color: #ffe867;
        background: #bfa92a;
        border: 1px solid #ffe867;
      }
      .icon {
        background-image: url(~@/assets/images/yellowloc.png) !important;
      }
    }
    .chart-tooltip-content {
      background-image: url(~@/assets/images/yellowbg.png);
      > div {
        > span {
          &:nth-of-type(2) {
            color: #ffe867;
          }
        }
      }
    }
  }
}

#container {
  width: 100%;
  height: 100%;
}

::v-deep .ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
}

::v-deep .ant-spin-dot {
  width: 100px;
  height: 100px;
}

::v-deep .ant-spin-blur::after {
  opacity: 0;
}
</style>
